import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdminData, initState } from './types';

const initialState: initState = {
  admin: {
    agentAccess: false,
    ambassadorAccess: false,
    analyticsAccess: false,
    catalogAccess: false,
    couponAccess: false,
    createdAt: '',
    customerAccess: false,
    eid: '',
    email: '',
    firstName: '',
    id: 0,
    lastAccessedAt: '',
    lastAccessedFrom: '',
    lastName: '',
    notificationAccess: false,
    operatorAccess: false,
    orderAccess: false,
    promotionAccess: false,
    restaurantAccess: false,
    settingAccess: false,
    dealAccess: false,
    state: '',
    updatedAt: '',
    accessToken: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAdminData(state: initState, action: PayloadAction<{ admin: AdminData }>) {
      state.admin = action.payload.admin;
    },
    setInitState(state) {
      state.admin = initialState.admin;
    },
  },
});

export const { setAdminData, setInitState } = authSlice.actions;

export default authSlice.reducer;
