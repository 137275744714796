import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Tooltip, Button, Pagination } from 'antd';
import { Apps, Edit } from '@material-ui/icons';

function OperatorTable({ intl, operatorData, total, onChangePage }) {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };
  const getPermissions = item => {
    let permissions = 0;
    const accessArr = [
      'agentAccess',
      'ambassadorAccess',
      'analyticsAccess',
      'catalogAccess',
      'couponAccess',
      'customerAccess',
      'notificationAccess',
      'operatorAccess',
      'orderAccess',
      'promotionAccess',
      'restaurantAccess',
      'dealAccess',
      'settingAccess',
    ];
    accessArr.map(v => {
      if (item[`${v}`]) {
        permissions += 1;
      }
      return permissions;
    });
    return permissions;
  };
  return (
    <div className="operator_table_layout">
      <div className="table_header">
        <div className="user_avatar">
          <Apps />
        </div>
        <div className="code">
          <FormattedMessage id="Code" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="email">
          <FormattedMessage id="Email" />
        </div>
        <div className="permissions">
          <FormattedMessage id="Permissions" />
        </div>
        <div className="last_access">
          <FormattedMessage id="Last access" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {operatorData &&
          operatorData.map((item, index) => (
            <div className={`table_item type_state_${item.state === 'A'}`} key={index}>
              <div className="user_avatar">
                {!item.avatar && <div className="photo_wrapper" />}
                {item.avatar && <Img src={`${process.env.REACT_APP_SERVER}/${item.avatar}`} alt="avatar" />}
              </div>
              <div className="code">
                <p className="title">{item.eid || ''}</p>
                <p className="subtitle">{item.id || ''}</p>
              </div>
              <div className="name">
                <p className="title">{`${item.firstName || ''} ${item.lastName || ''}`}</p>
              </div>
              <div className="email">
                <p className="title">{item.email || ''}</p>
              </div>
              <div className="permissions">
                <p className="title">{getPermissions(item) || '0'} capacidades</p>
              </div>
              <div className="last_access">
                {item.lastAccessedAt && (
                  <p className="title">
                    {`${moment(item.lastAccessedAt).tz('America/Guatemala').format('LL')}
                      @${moment(item.lastAccessedAt).tz('America/Guatemala').format('HH:mm')}`}
                  </p>
                )}
                {item.lastAccessedFrom && <p className="subtitle">IP {item.lastAccessedFrom || ''}</p>}
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit user' })}>
                  <NavLink to={`/dashboard/operators/${item.id || ''}`}>
                    <Button className="edit_btn">
                      <Edit />
                    </Button>
                  </NavLink>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
    </div>
  );
}

OperatorTable.defaultProps = {
  operatorData: [],
  total: 0,
};

export default compose(injectIntl)(OperatorTable);
