import { api } from '..';
import {
  CallsReportData,
  CybersourceReportData,
  ListReportData,
  OrderReportData,
  PointReportData,
  UsersReportData,
} from './types';

export const reportApi = api.enhanceEndpoints({ addTagTypes: ['Report'] }).injectEndpoints({
  endpoints: build => ({
    exportPointReport: build.query<PointReportData, { fromDate: string; toDate: string; userID: string }>({
      query: ({ fromDate, toDate, userID }) => ({
        url: `v0/report/exportPointReport?fromDate=${fromDate}&toDate=${toDate}${
          userID !== '' ? `&userID=${userID}` : ''
        }`,
        method: 'GET',
      }),
    }),
    exportOrderReport: build.query<
      OrderReportData,
      { fromDate: string; toDate: string; format: string; state: string }
    >({
      query: ({ fromDate, toDate, format, state }) => ({
        url: `v0/report/exportOrderReport?fromDate=${fromDate}&toDate=${toDate}&format=${format}&state=${state}`,
        method: 'GET',
      }),
    }),
    exportActivityAccessReport: build.query<UsersReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v0/report/exportActivityAccessReport?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    exportActivityRegisterReport: build.query<UsersReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v0/report/exportActivityRegisterReport?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    exportPromotionalProductReport: build.query<
      UsersReportData,
      { fromDate: string; toDate: string; promoProductId: number; channel: string }
    >({
      query: ({ fromDate, toDate, promoProductId, channel }) => ({
        url: `v0/report/exportPromotionalProductReport`,
        params: {
          fromDate,
          toDate,
          promoProductId,
          channel,
        },
        method: 'GET',
      }),
    }),
    getReportList: build.query<ListReportData, any>({
      query: () => ({
        url: `v0/report`,
        method: 'GET',
      }),
    }),
    getReportListV1: build.query<ListReportData, any>({
      query: () => ({
        url: `v1/report`,
        method: 'GET',
      }),
    }),
    exportActivityOrderReport: build.query<UsersReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v0/report/exportActivityOrderReport?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    exportCybersourceReport: build.query<CybersourceReportData, { fromDate: string; toDate: string; platform: string }>(
      {
        query: ({ fromDate, toDate, platform }) => ({
          url: `v1/report/exportCybersourceReport?fromDate=${fromDate}&toDate=${toDate}&platform=${platform}`,
          method: 'GET',
        }),
      },
    ),
    exportCallsReport: build.query<CallsReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v0/report/customer-calls?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    downloadReport: build.query<Blob, { filename: string }>({
      query: ({ filename }) => ({
        url: `v0/report/downloadReport/${filename}`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),
    downloadReportV1: build.query<Blob, { filename: string }>({
      query: ({ filename }) => ({
        url: `v1/report/downloadReport/${filename}`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),
  }),
});

export const {
  useLazyExportPointReportQuery,
  useLazyExportOrderReportQuery,
  useLazyExportActivityAccessReportQuery,
  useLazyExportActivityRegisterReportQuery,
  useLazyGetReportListQuery,
  useLazyExportActivityOrderReportQuery,
  useLazyExportCybersourceReportQuery,
  useLazyExportCallsReportQuery,
  useLazyDownloadReportQuery,
  useLazyDownloadReportV1Query,
  useLazyGetReportListV1Query,
  useLazyExportPromotionalProductReportQuery,
} = reportApi;
